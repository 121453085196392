import React from 'react';
import Button from 'cv-app/shared/ui/molecules/button/button';
import { getUserLocaleAndCountry } from 'cv-app/utils/helpers/user-language-helper';
import * as Bowser from 'bowser';
import { useTranslation } from "react-i18next";

export interface ErrorModalProps {
    modalId: string;
    isVisible: boolean;
    errorMessage: string;
    otherErrMessage?: string;
    code: string;
    buttonsPosition?: 'start' | 'end' | 'center' | 'between' | 'around';
    textCancel?: string;
    textAccept?: string;
    customClass?: string;
    handleCancel?: () => void;
    handleAccept?: () => void;  
}

export function ErrorModal(props: ErrorModalProps) {
    const { os, platform, browser } = Bowser.parse(window.navigator.userAgent);
    const { t } = useTranslation();
    return (
        <div className={`o-modal-wrapper ${props.isVisible ? "is-active" : ""}`}
            tabIndex={-1} role="dialog" aria-labelledby={props.modalId} aria-hidden={!props.isVisible}>      
            <div className="o-modal c-box u-margin-auto u-padding-none" role="document">
                <div className="o-modal__body" id={props.modalId}>
                    <div>
                        <h2>{t('error_modal__title')}</h2>
                        <div>{props.code}</div>
                        <div>{t('error_modal__subtitle')}</div>
                        <br/>
                        <div className='error-message'>
                            <style>{` h2 { color: black; } .error-message { padding: 40px; border-radius: 20px; background-color: #eee; } `}</style>
                            { navigator.onLine ? 
                            props.errorMessage : 
                            'Please check your internet connection.' }
                            <br />
                            <br />
                            Please log a <a href="https://delaware-internal.4me.com/self-service/requests/new" target="_blank">Xurrent</a> ticket for this issue.
                            <br />
                            {props.otherErrMessage}
                        </div>
                        <br/>
                        <div>{new Date().toLocaleString("en-GB")}</div>
                        <div>{getUserLocaleAndCountry().country}</div>
                        <div>{`${os.name} ${os.versionName} ${os.version} ${platform.type}`}</div>
                        <div>{`${browser.name} ${browser.version}`}</div>
                        <br/>
                    </div>
                    <div className={`o-flex o-flex--justify-content-${props.buttonsPosition || "end"}`}>
                        {props.textCancel &&
                        <Button
                            buttonText={props.textCancel}
                            btnType='secondary'
                            callbackOnClick={props.handleCancel}
                        ></Button>
                        }
                        {props.textAccept &&
                        <Button
                            buttonText={props.textAccept}
                            btnType='primary'
                            callbackOnClick={props.handleAccept}
                        ></Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorModal;