import React, { useState }  from 'react';
import FormInput from 'cv-app/shared/ui/molecules/form-input/form-input';
import Media from 'cv-app/shared/ui/molecules/media/media';
import FormSelect, { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import './personal-info.module.scss';
import { PersonalInfoFormData } from 'cv-app/shared/services/personal-information/personal-types';
import { useTranslation } from 'react-i18next';
import { RichTextEditor } from 'cv-app/shared/ui/components/rich-text-editor/rich-text-editor';
import { htmlDecode } from 'cv-app/shared/services/html-serialize/html-serialize';
import { cleanHtml } from 'cv-app/shared/services/html-serialize/html-serialize';

/* eslint-disable-next-line */
export interface PersonalInfoProps {
  profileImg: string;
  personalInfoSectionData: PersonalInfoFormData
  doValidate: boolean;
  dataChangeCallback: (data: PersonalInfoFormData) => void;
  teamOptions: SelectOption[];
  nationalitites: SelectOption[];
  legalEntities: SelectOption[];
  noOdsUserData: boolean;
}

export function PersonalInfo(props: PersonalInfoProps) {  
  const [isMaxLengthError, setIsMaxLengthError] = useState(false);
  const { t } = useTranslation();

  const handleEntityChange = (newValue: string) => {
    const data = { ...props.personalInfoSectionData };
    const legalEntityId = parseInt(newValue);
    if (isNaN(legalEntityId)) {
      return;
    }
    data.legalEntityId = legalEntityId;
    props.dataChangeCallback(data);
  };

  const handleTeamChange = (newValue: string) => {
    const data = { ...props.personalInfoSectionData };
    const teamId = parseInt(newValue);
    if (isNaN(teamId)) {
      return;
    }
    data.teamId = teamId;
    props.dataChangeCallback(data);
  };

  const handleNationalityChange = (newValue: string) => {
    const data = { ...props.personalInfoSectionData };
    const nationalityId = parseInt(newValue);
    if (isNaN(nationalityId)) {
      return;
    }
    data.nationalityId = nationalityId;
    props.dataChangeCallback(data);
  };

  const handleDescriptionChange = (newValue: string) => {
    const data = { ...props.personalInfoSectionData };
    data.description = newValue;
    props.dataChangeCallback(data);
  };

  const handleJobTitleChange = (newValue: string) => {
    if (newValue.length > 80) {
      setIsMaxLengthError(true);
    } else {
      setIsMaxLengthError(false);
    }
    const data = { ...props.personalInfoSectionData };
    data.jobTitle = newValue;
    props.dataChangeCallback(data);
  };

  const handleFirstNameChange = (newValue: string) => {
    if (newValue.length > 80) {
      setIsMaxLengthError(true);
    } else {
      setIsMaxLengthError(false);
    }
    const data = { ...props.personalInfoSectionData };
    data.firstName = newValue;
    props.dataChangeCallback(data);
  };

  const handleLastNameChange = (newValue: string) => {
    if (newValue.length > 80) {
      setIsMaxLengthError(true);
    } else {
      setIsMaxLengthError(false);
    }
    const data = { ...props.personalInfoSectionData };
    data.lastName = newValue;
    props.dataChangeCallback(data);
  };

  return (
    <React.Fragment>
      <div className="c-form__section c-personal-info">
        <div className="c-personal-info__fields-wrapper">
          <FormInput
            isRequired={true}
            isDisabled={!props.noOdsUserData}
            type='input'
            doValidate={props.doValidate}
            inputValue={props.personalInfoSectionData.firstName}
            inputId="personal_firstName"
            labelText={t("personal-information__lbl-firstName")}
            errorText={t("personal-information__lbl-firstNameError")}
            minLength={2}
            maxLenght={80}
            maxLengthErrorText={props.noOdsUserData ? t('limit_reached') + " (" + props.personalInfoSectionData.firstName.length + t("characters") + ")" : t('limit_reached')}
            isMaxLengthError={isMaxLengthError}         
            onChangeCallback={handleFirstNameChange}
          ></FormInput>
          <FormInput
            isRequired={true}
            isDisabled={!props.noOdsUserData}
            type='input'
            doValidate={props.doValidate}
            inputValue={props.personalInfoSectionData.lastName}
            inputId="personal_lastName"
            labelText={t("personal-information__lbl-lastName")}
            errorText={t("personal-information__lbl-lastNameError")}
            minLength={2}
            maxLenght={80}
            maxLengthErrorText={props.noOdsUserData ? t('limit_reached') + " (" + props.personalInfoSectionData.lastName.length + t("characters") + ")" : t('limit_reached')}
            isMaxLengthError={isMaxLengthError}
            onChangeCallback={handleLastNameChange}
          ></FormInput>
          <FormSelect
            inputId="personal_nationality"
            inputName="personalNationality"
            inputValue={props.personalInfoSectionData.nationalityId ? props.personalInfoSectionData.nationalityId.toString() : ''}
            isRequired={false}
            doValidate={false}
            onChangeCallback={handleNationalityChange}
            labelText={t("personal-information__lbl-nationality")}
            selectDataSource={props.nationalitites}
            defaultValue={`${t('general__select-choose')} ${t("personal-information__lbl-nationality")}`}
            defaultValueOption={`${t('general__select-choose')} ${t("personal-information__lbl-nationality")}`}
          ></FormSelect>
        </div>
        <div className="c-personal-info__media-wrapper">
          <Media
            imgSrc={props.profileImg}
            forceFit={true}
            extraCssClass="c-personal-info__media"
            imgAlt="profile-pic"
            rounded={true}
            hasBorder={false}
            placeholderSvgProps={
              {
                svgId: "icon-people",
                svgHeight: 135,
                svgWidth: 106
              }
            }
          ></Media>
          <div className='w-100'>
            <FormSelect
              inputId="personal_entity"
              inputName="personalEntity"
              inputValue={props.personalInfoSectionData.legalEntityId === 0 ? '-1' : props.personalInfoSectionData.legalEntityId.toString()}
              isRequired={true}
              doValidate={props.doValidate}
              onChangeCallback={handleEntityChange}
              labelText={t("personal-information__lbl-entity")}
              errorText={t("personal-information__lbl-entityError")}
              selectDataSource={props.legalEntities}
              defaultValue={`${t('general__select-choose')} ${t("personal-information__lbl-entity")}`}
            ></FormSelect>
          </div>
        </div>
      </div>
      <div className="c-form__section">
        <FormInput
          isRequired={true}
          type='input'
          doValidate={props.doValidate}
          inputValue={props.personalInfoSectionData.jobTitle}
          inputId="personal_jobTitle"
          labelText={t("personal-information__lbl-jobTitle")}
          maxLenght={80}
          onChangeCallback={handleJobTitleChange}
          minLength={2}
          errorText={t("personal-information__lbl-jobTitleError")}
          maxLengthErrorText={props.noOdsUserData ? t('limit_reached') + " (" + props.personalInfoSectionData.jobTitle.length + t("characters") + ")" : t('limit_reached')}
          isMaxLengthError={isMaxLengthError}
        ></FormInput>
        <FormSelect
          inputId="personal_team"
          inputName="personalTeam"
          inputValue={props.personalInfoSectionData.teamId.toString()}
          isRequired={true}
          isDisabled={!props.teamOptions.length}
          doValidate={props.doValidate}
          onChangeCallback={handleTeamChange}
          labelText={`${t("personal-information__lbl-team")} (${t("personal-information__lbl-team-entity")})`}
          errorText={t("personal-information__lbl-teamError")}
          selectDataSource={props.teamOptions}
          defaultValue={`${t('general__select-choose')} ${t("personal-information__lbl-team")}`}
        ></FormSelect>
      </div>
      <RichTextEditor
        title={t("personal-information__lbl-description")}
        value={props.personalInfoSectionData.description}
        placeholder={t("personal-information__description-placeholder")}
        helpText={t("personal-information__help-text")}
        errorText={t("personal-information__profile-description-error") + " (" + (200 - htmlDecode(props.personalInfoSectionData.description).length) + t("remaining") + ")"}
        doValidate={props.doValidate}
        onChange={handleDescriptionChange}
        subtitle={t("character_limit")}
        maxLength={700}
        maxLengthErrorText={t('limit_reached') + " (" + htmlDecode(props.personalInfoSectionData.description).length + t("characters") + ")"}
      />
    </React.Fragment>
  );
}

export default PersonalInfo;
